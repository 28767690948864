<template>
  <b-card-actions action-refresh @refresh="onRefresh" ref="mainCard">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <flat-pickr
        v-model="rangeDate"
        class="form-control bg-white w-25"
        :config="{
          mode: 'range',
          wrap: true,
          dateFormat: 'm',
          wrap: true,
          altInput: true,
          altFormat: 'F',
          onChange: handleDateChange,
        }"
        placeholder="Range Date from - to"
      />
      <b-button variant="primary" v-b-modal.add-milestone>
        <feather-icon icon="PlusIcon"></feather-icon> Milestone
      </b-button>
    </div>
    <VueDjGantt
      :from="from"
      :to="to"
      :list="list"
      :rows="rows"
      :items="items"
      @resized="onResize($event)"
      @moved="onMove($event)"
      @row="detailRow($event)"
      @item="detailItem($event)"
      @cell="detailCell($event)"
      locale="id"
    />

    <b-modal id="add-milestone" title="Add Milestone" size="md" hide-footer>
      <b-form @submit.prevent="submit">
        <b-form-group label="Title" label-for="title">
          <b-form-input
            id="title"
            v-model="form.title"
            type="text"
            placeholder="Enter Title"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Project" label-for="project">
          <b-form-select
            id="project"
            v-model="form.project"
            :options="projects"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Planned Start Date" label-for="start_date">
          <flat-pickr
            v-model="form.start_date"
            class="form-control bg-white"
            :config="{
              dateFormat: 'Y-m-d',
              wrap: true,
              altInput: true,
              altFormat: 'F j, Y',
            }"
            placeholder="Select start date"
          />
        </b-form-group>
        <b-form-group label="Planned Due Date" label-for="due_date">
          <flat-pickr
            v-model="form.due_date"
            class="form-control bg-white"
            :config="{
              dateFormat: 'Y-m-d',
              wrap: true,
              altInput: true,
              altFormat: 'F j, Y',
            }"
            placeholder="Select due date"
          />
        </b-form-group>
        <b-form-group label="Status" label-for="status">
          <b-form-select
            id="status"
            v-model="form.status"
            :options="statusOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Priority" label-for="priority">
          <b-form-select
            id="priority"
            v-model="form.priority"
            :options="priorityOptions"
          ></b-form-select>
        </b-form-group>
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>

    <b-modal
      v-for="row in rows"
      :key="row.id"
      :id="`detail-${row.id}`"
      title="Detail Milestone"
      size="md"
      hide-footer
    ></b-modal>
  </b-card-actions>
</template>

<script>
import moment from "moment";

export default {
  components: {},

  data() {
    return {
      list: [
        {
          id: "internalId",
          width: 80,
          header: {
            content: "# ID",
          },
        },
        {
          id: "name",
          width: 200,
          header: {
            content: "Title",
          },
        },
      ],

      from: +(+new Date()) - 2 * 24 * 60 * 60 * 1000,
      to: moment().startOf("day").add(1, "months"),

      rows: [],

      items: [],

      rangeDate: [],

      form: {
        title: "",
        start_date: "",
        due_date: "",
        status: "",
        priority: "",
        project: "",
      },

      statusOptions: [
        { value: 0, text: "Open" },
        { value: 1, text: "In Progress" },
        { value: 2, text: "Done" },
      ],

      priorityOptions: [
        { value: "low", text: "Low" },
        { value: "medium", text: "Medium" },
        { value: "high", text: "High" },
      ],

      projects: [],
    };
  },

  mounted() {
    this.get_data();
  },

  methods: {
    onRefresh() {
      this.get_data();
    },

    get_data() {
      this.rows = [];
      this.items = [];
      this.$refs["mainCard"].showLoading = true;
      this.callApi({
        method: "GET",
        url: "/milestones/fetch",
        success: (response) => {
          // foreach data from response
          response.result.forEach((element) => {
            // push data to rows
            this.rows.push({
              id: element.id,
              internalId: element.id,
              name: element.name,
            });

            // push data to items
            this.items.push({
              rowId: element.id,
              label: element.name,
              style: { background: "#24abf2" },
              time: {
                start: moment(element.start_date),
                end: moment(element.due_date).add(1, "days"),
              },
              resizable: true,
              moveable: true,
            });
          });
          this.$refs["mainCard"].showLoading = false;
        },
      });

      this.callApi({
        method: "GET",
        url: "/projects/fetch",
        success: (response) => {
          // foreach data from response
          response.result.data.forEach((element) => {
            // push data to rows
            this.projects.push({
              value: element.id,
              text: element.name,
            });
          });
        },
      });

      this.$refs["mainCard"].showLoading = false;
    },

    submit() {
      let data = {
        name: this.form.title,
        start_date: this.form.start_date,
        due_date: this.form.due_date,
        status: this.form.status,
        priority: this.form.priority,
        project_id: this.form.project,
        created_by: JSON.parse(localStorage.getItem("sw_auth_data")).id,
      };

      this.callApi({
        method: "POST",
        url: "/milestones/create",
        data: data,
        success: (response) => {
          this.$bvModal.hide("add-milestone");
          this.$bvToast.toast("Milestone added successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });

          this.get_data();
        },
      });
    },

    onResize(event) {
      let time = event.item.item.time.end;

      console.log(time);
    },

    onMove(event) {
      console.log(event);
    },

    handleDateChange() {
      let range = String(this.rangeDate)
        .split(" to ")
        .map((str) => parseInt(str));

      this.from = moment({
        year: moment().year(),
        month: range[0] - 1,
        day: 1,
      });
      this.to = moment({
        year: moment().year(),
        month: range[1],
        day: 1,
      });
    },

    detailRow(row) {
      console.log(row);
      this.$bvModal.show(`detail-${row.id}`);
    },

    detailItem(item) {
      console.log(item);
    },

    detailCell(cell) {
      console.log(cell.item.item.value.id);
    },
  },
};
</script>